import React from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import {FormattedMessage} from 'react-intl';
import Button from 'react-bootstrap/lib/Button';
import ToggleButtonGroup from 'react-bootstrap/lib/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/lib/ToggleButton';
import classNames from 'classnames';

import Icon from 'components/Icon';
import {ViewContentsModalTab} from 'components/CoursebookContentsViewer/interface';
import './ViewContentsModal.scss';

interface Props {
  isToggleGroup?: boolean;
  title?: JSX.Element;
  show: boolean;
  className?: string;
  renderBody: (tab: ViewContentsModalTab) => React.ReactNode;
  close: () => void;
}

interface State {
  selectedTab: ViewContentsModalTab;
}

class ViewContentsModal extends React.Component<Props, State> {
  public state: State = {selectedTab: ViewContentsModalTab.UNITS};

  public render() {
    const {show, renderBody, isToggleGroup, title, className} = this.props;
    return (
      <Modal
        show={show}
        onHide={this.close}
        className={classNames('view-contents-modal', {adaptive: isToggleGroup})}
        backdrop="static"
        dialogClassName={className}
      >
        <Modal.Header>
          <Modal.Title>
            {title ? title : <FormattedMessage id="ViewCoursebookContentsModal.Title" />}
            {!isToggleGroup && (
              <ToggleButtonGroup
                type="radio"
                bsSize="sm"
                name="ViewContentsModalTab"
                value={this.state.selectedTab}
                className="contents-modal-tab-switch"
                onChange={this.changeSelectedTab}
              >
                <ToggleButton value={ViewContentsModalTab.UNITS}>
                  <FormattedMessage id="ViewCoursebookContentsModal.Units" />
                </ToggleButton>
                <ToggleButton value={ViewContentsModalTab.SECTIONS}>
                  <FormattedMessage id="ViewCoursebookContentsModal.Sections" />
                </ToggleButton>
              </ToggleButtonGroup>
            )}
            <a onClick={this.close}>
              <Icon name="pc-close" tag="i" />
            </a>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{renderBody(this.state.selectedTab)}</Modal.Body>
        <Modal.Footer>
          <Button bsStyle="primary" bsSize="sm" onClick={this.close}>
            <FormattedMessage id="Common.Close" />
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  private close = () => {
    this.setState({
      selectedTab: ViewContentsModalTab.UNITS
    });
    this.props.close();
  };

  private changeSelectedTab = (tabName: ViewContentsModalTab) =>
    this.setState({selectedTab: tabName});
}

export default ViewContentsModal;
