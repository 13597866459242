import {type Action} from 'redux';

import {type AxiosRequestAction, type AxiosResponseAction} from 'services/axios/interface';
import {type ExerciseJSON} from 'store/exercise/player/interface';
import {type CoursebookUnit} from 'store/interface';

import {
  REQUEST_UNIT_PREVIEW_PAGE,
  REQUEST_UNIT_PREVIEW,
  INIT_UNIT_PREVIEW,
  CLEAN_UNIT_PREVIEW,
  SET_MAIN_EXERCISE_ID,
  REQUEST_UNIT_PREVIEW_ADDITIONAL_EXERCISES,
  INIT_UNIT_PREVIEW_ADDITIONAL,
  CLEAR_MAIN_EXERCISE_ID,
  SET_GRAMMAR_PREVIEW_OPEN,
  CLEAR_GRAMMAR_PREVIEW_OPEN
} from './actionTypes';

export interface InitUnitPreviewAction extends Action {
  coursebookId: string;
  unitId: number;
  exercises: ExerciseJSON[];
  addExerciseNumber: boolean;
  pageNumber?: number;
}

export interface InitUnitPreviewAdditionalAction extends Action {
  exercise: ExerciseJSON;
}

export interface UnitPreviewSetMainIdAction extends Action {
  mainId: string;
}

export interface UnitPreviewSetGrammarPreviewOpenAction extends Action {
  exerciseId?: string;
}

export type RequestUnitForPreviewResponseAction = AxiosResponseAction<CoursebookUnit>;

export const requestUnitForPreview = (
  coursebookId: string,
  unitId: number
): AxiosRequestAction => ({
  type: REQUEST_UNIT_PREVIEW,
  payload: {
    client: 'v2',
    request: {
      method: 'get',
      url: `/v2/coursebook/${coursebookId}/unit/${unitId}`,
      params: {
        expand: ['overview', 'intro']
      }
    }
  }
});

export const requestPreviewUnitPage = (
  coursebookId: string,
  unitId: number,
  page: number
): AxiosRequestAction => ({
  type: REQUEST_UNIT_PREVIEW_PAGE,
  payload: {
    client: 'v2',
    request: {
      method: 'get',
      url: `/v2/coursebook/${coursebookId}/unit/${unitId}/exercise/page/${page}`,
      params: {
        expand: 'grammar'
      }
    }
  }
});

export const requestUnitPreviewAdditionalExercises = (
  coursebookId: string,
  unitId: number,
  mainId: string
): AxiosRequestAction => ({
  type: REQUEST_UNIT_PREVIEW_ADDITIONAL_EXERCISES,
  payload: {
    client: 'v2',
    request: {
      method: 'get',
      url: `/v2/coursebook/${coursebookId}/unit/${unitId}/exercise/${mainId}/additional`,
      params: {
        expand: 'grammar'
      }
    }
  }
});

export const initUnitPreview = (
  coursebookId: string,
  unitId: number,
  exercises: ExerciseJSON[],
  addExerciseNumber: boolean = false,
  pageNumber?: number
): InitUnitPreviewAction => ({
  type: INIT_UNIT_PREVIEW,
  coursebookId,
  unitId,
  exercises,
  addExerciseNumber,
  pageNumber
});

export const initUnitPreviewAdditional = (
  exercise: ExerciseJSON
): InitUnitPreviewAdditionalAction => ({
  type: INIT_UNIT_PREVIEW_ADDITIONAL,
  exercise
});

export const cleanUnitPreview = {
  type: CLEAN_UNIT_PREVIEW
};

export const setMainExerciseId = (mainId: string): UnitPreviewSetMainIdAction => ({
  type: SET_MAIN_EXERCISE_ID,
  mainId
});

export const clearMainExerciseId: Action = {
  type: CLEAR_MAIN_EXERCISE_ID
};

export const setGrammarPreviewOpen = (
  exerciseId: string
): UnitPreviewSetGrammarPreviewOpenAction => ({
  type: SET_GRAMMAR_PREVIEW_OPEN,
  exerciseId
});

export const clearGrammarPreviewOpen = (): Action => ({
  type: CLEAR_GRAMMAR_PREVIEW_OPEN
});
