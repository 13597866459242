import {type Value} from '@englex/slate';

export interface ContentsItem {
  id: number;
  title: string;
  description: Value;
}

export enum ViewContentsModalTab {
  UNITS = 'UNITS',
  SECTIONS = 'SECTIONS'
}
